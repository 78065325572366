import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { formatDate } from "../../utils/dateUtils";

// Thunk for adding ration meal
export const addProduct = createAsyncThunk(
  "ration/addProduct",
  async ({ profileId, data }, { dispatch, rejectWithValue }) => {
    try {
      const processedDate = formatDate(data.date);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/ration/add/${profileId}`,
        {
          ...data,
          date: processedDate, // Use the processed date for the API request
        },
        {
          params: { date: processedDate }, // Use the processed date in params
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
            "ngrok-skip-browser-warning": "true",
          },
        }
      );

      // Dispatch fetchRation with the processed date
      dispatch(fetchRation({ profileId, startDate: data.date }));

      return response.data; // You can still return this if needed
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Thunk for fetching ration
export const fetchRation = createAsyncThunk(
  "ration/fetchRation",
  async ({ profileId, startDate }, { rejectWithValue }) => {
    try {
      const dateStr = formatDate(startDate);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ration/${profileId}?date=${dateStr}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
            "ngrok-skip-browser-warning": "true",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Thunk for deleting a meal from ration
export const deleteDietItem = createAsyncThunk(
  "ration/deleteDietItem",
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/ration/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
            "ngrok-skip-browser-warning": "true",
          },
        }
      );
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Thunk for updating a meal
export const updateDietItem = createAsyncThunk(
  "ration/updateDietItem",
  async (
    { mealId, updatedData, profileId, startDate },
    { dispatch, rejectWithValue }
  ) => {
    try {
      // Send the PUT request to update the meal
      await axios.put(
        `${process.env.REACT_APP_API_URL}/ration/update/${mealId}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
            "ngrok-skip-browser-warning": "true",
          },
        }
      );

      // Return the mealId and updated data directly since the response doesn't contain it
      return { mealId, updatedData }; // Use the data you sent in the request
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const rationSlice = createSlice({
  name: "ration",
  initialState: {
    dietItems: [],
    date: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRation.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload === "") {
          state.dietItems = [];
        } else {
          state.dietItems = action.payload.meals
            .map((meal) => ({
              product: {
                id: meal.product.id,
                name: meal.product.name,
                calories: meal.product.calories,
                proteins: meal.product.proteins,
                fats: meal.product.fats,
                carbs: meal.product.carbs,
              },
              time: meal.time,
              id: meal.id,
              grams: meal.grams,
            }))
            .sort((a, b) => {
              const dateA = new Date(`1970-01-01T${a.time}`);
              const dateB = new Date(`1970-01-01T${b.time}`);

              // First, compare by date
              const dateComparison = dateA - dateB;

              // If dates are equal, compare by id
              if (dateComparison === 0) {
                return a.id - b.id; // Adjust this based on the data type of id (use parseInt if it's a string)
              }

              return dateComparison; // Return the date comparison result
            });
        }
      })
      .addCase(fetchRation.rejected, (state, action) => {
        state.status = "failed";
        console.error(action.payload);
        state.error = action.payload || "Failed to fetch ration data";
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to add product";
      })
      .addCase(updateDietItem.fulfilled, (state, action) => {
        state.status = "succeeded";

        const { mealId, updatedData } = action.payload; // Get the mealId and the data sent to the server

        // Ensure dietItems is an array before proceeding
        if (Array.isArray(state.dietItems)) {
          // Find the index of the meal in the state
          const existingMealIndex = state.dietItems.findIndex(
            (meal) => meal.id === mealId
          );

          if (existingMealIndex !== -1) {
            // Update the meal with the data that was sent
            state.dietItems[existingMealIndex] = {
              ...state.dietItems[existingMealIndex],
              ...updatedData, // Merge the updated fields with existing meal
            };
          } else {
            console.error(`Meal with ID ${mealId} not found.`);
          }
        } else {
          console.error("dietItems array is undefined or not initialized.");
        }
      })
      .addCase(updateDietItem.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to update diet item";
      })
      .addCase(deleteDietItem.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteDietItem.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dietItems = state.dietItems.filter(
          (item) => item.id !== action.payload
        );
      })
      .addCase(deleteDietItem.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to delete diet item";
      });
  },
});

export default rationSlice.reducer;
