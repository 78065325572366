import React, { useState } from "react";
import { useAuth } from "../../AuthContext"; // Import the useAuth hook
import loginStyles from "./Login.module.css";
import sideImageStyles from "./LoginSideImage/LoginSideImage.module.css";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { useTranslation } from "react-i18next"; // Import useTranslation from react-i18next
import LanguageSelector from "../LanguageSelector/LanguageSelector"; // Import LanguageSelector

function Login() {
  const { login } = useAuth(); // Destructure the login function from context
  const { t } = useTranslation(); // Use the translation hook
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [view, setView] = useState("signIn");
  const [loginError, setLoginError] = useState(""); // Single state for login errors

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setLoginError(""); // Clear any previous errors when the user types
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setLoginError(""); // Clear any previous errors when the user types
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const isFormValid = email && password;

  const handleLogin = async () => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !password) {
      setLoginError(t("login.fillAllFields")); // Check if fields are empty
      return;
    }

    // if (!emailRegex.test(email)) {
    //   setLoginError(t("login.invalidEmail")); // Check if email is invalid
    //   return;
    // }

    const errorMessage = await login(email, password);
    if (errorMessage) {
      setLoginError(errorMessage); // Display backend error message
    }
  };

  return (
    <div className={`${loginStyles.Login}`}>
      <div className={`${sideImageStyles["left-side"]}`}>
        <div className={`${sideImageStyles["centered-container"]}`}>
          <div
            className={`${sideImageStyles.text} ${sideImageStyles["centered-text"]}`}
          >
            {t("login.loginMotto")
              .split(" ")
              .map((word, index) => (
                <span
                  key={index}
                  className={index === 2 ? sideImageStyles.highlight : ""}
                >
                  {word}{" "}
                </span>
              ))}
          </div>
        </div>
      </div>
      <div className={`${loginStyles["right-side"]}`}>
        <div className={`${loginStyles.languageSelector}`}>
          {" "}
          <LanguageSelector />
        </div>
        {view === "signIn" && (
          <div className={`${loginStyles["login-form"]}`}>
            <h2>{t("login.signIn")}</h2>
            <form className={`${loginStyles["inside-form"]}`}>
              <div
                className={`${loginStyles["form-group"]} ${loginStyles["left-align"]}`}
              >
                <label
                  htmlFor="email"
                  className={`${loginStyles["input-label"]} ${loginStyles["input-padding"]} ${loginStyles["text"]}`}
                >
                  {t("login.email")}
                </label>
                <div className={loginStyles["input-container"]}>
                  <input
                    className={`${loginStyles["email-input"]} ${
                      loginStyles["input-padding"]
                    } ${loginStyles["text"]} ${
                      email ? loginStyles["not-empty"] : ""
                    }`}
                    type="email"
                    id="email"
                    name="email"
                    placeholder={t("login.email")}
                    value={email}
                    onChange={handleEmailChange}
                    autoComplete="email"
                  />
                </div>
              </div>
              <div
                className={
                  loginStyles["form-group"] +
                  " " +
                  loginStyles["left-align"] +
                  " "
                }
              >
                {" "}
                <div className="row justify-content-between align-items-center">
                  <div className="col-10">
                    <label
                      htmlFor="password"
                      className={`${loginStyles["input-padding"]} ${loginStyles["input-label"]} ${loginStyles["text"]}`}
                    >
                      {t("login.password")}
                    </label>
                    <div className="input-with-button">
                      <input
                        className={`${loginStyles["email-input"]} ${
                          loginStyles["input-padding"]
                        } ${loginStyles["text"]} ${
                          password ? loginStyles["not-empty"] : ""
                        }`}
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder={t("login.password")}
                        value={password}
                        onChange={handlePasswordChange}
                        autoComplete="current-password"
                      />
                    </div>
                  </div>
                  <div className="col-auto d-flex align-items-center pt-2">
                    <button
                      type="button"
                      onClick={handleShowPassword}
                      className={loginStyles["show-password-button"]}
                    >
                      <i
                        className={`bi ${
                          showPassword
                            ? `${loginStyles["bi-eye-slash"]} bi-eye-slash`
                            : `${loginStyles["bi-eye"]} bi-eye`
                        }`}
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className={loginStyles["forgot-password"]}>
                <Link
                  to="/reset-password"
                  className={`${loginStyles["no-underline-link"]}`}
                >
                  {t("login.forgotPassword")}
                </Link>
              </div>
              {loginError && (
                <div className={`${loginStyles["error-message"]}`}>
                  {loginError}
                </div>
              )}
              <button
                className={`${loginStyles["continue-button"]} ${
                  !isFormValid ? "opacity-50" : ""
                }`}
                type="button"
                onClick={handleLogin}
                disabled={!isFormValid}
              >
                {t("login.continue")}
              </button>

              <div className={loginStyles["signup"]}>
                <p>
                  <Link
                    to="/signup"
                    className={`${loginStyles["no-underline-link"]}`}
                  >
                    {t("login.newUserSignUp")}
                  </Link>
                </p>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
