import React, { useState } from "react";
import styles from "../HomePage/Header/Header.module.css";
import ukFlag from "../../assets/icons/ukraine-flag.svg";
import enFlag from "../../assets/icons/united-kingdom-flag.svg";
import i18n, { changeLanguage } from "../../configuration/i18n";

const LanguageSelector = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
    setIsOpen(false); // Close dropdown after selection
  };

  return (
    <div className={styles.languageSelector}>
      <button className={styles.dropdownToggle} onClick={toggleDropdown}>
        🌐 {i18n.language === "uk" ? "🇺🇦" : "🇬🇧"}
      </button>
      {isOpen && (
        <div className={styles.dropdownMenu}>
          <button
            className={styles.flagButton}
            onClick={() => handleLanguageChange("uk")}
          >
            <img src={ukFlag} alt="Ukrainian" /> Українська
          </button>
          <button
            className={styles.flagButton}
            onClick={() => handleLanguageChange("en")}
          >
            <img src={enFlag} alt="English" /> English
          </button>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
