import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser, resetProfileState } from "./redux/slices/profileSlice";
import {
  fetchProfiles,
  resetProfilesState,
} from "./redux/slices/profilesDataSlice";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Axios interceptor to catch 401 errors
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          // Handle token expiration or manual invalidation
          localStorage.removeItem("jwtToken");
          setIsAuthenticated(false);
          navigate("/login"); // Redirect to login page
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor); // Cleanup the interceptor on unmount
    };
  }, [navigate]);

  useEffect(() => {
    // Check if there's a token in localStorage and validate it
    const checkAuthStatus = async () => {
      const token = localStorage.getItem("jwtToken");
      if (token !== null && token !== undefined) {
        try {
          // TODO: Change fetchProdilfes call to auth/validate-token or other approach
          dispatch(fetchProfiles());

          setIsAuthenticated(true);
        } catch (error) {
          console.error("Token validation failed:", error);
          localStorage.removeItem("jwtToken"); // Remove invalid token
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false); // Set loading to false once the check is complete
    };
    checkAuthStatus();
  }, []);

  // Signup function for registering new users
  const signup = async (firstName, lastName, email, password) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        {
          firstName,
          lastName,
          login: email, // login refers to the user's email
          password,
        }
      );

      // Assuming the response contains a token (for example)
      // const token = response.data.token;
      // localStorage.setItem("jwtToken", token);
      // setIsAuthenticated(true);
    } catch (error) {
      // Pass the error up to be handled in handleSignup
      throw error;
    }
  };

  const login = async (email, password) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
          login: email,
          password: password,
        }
      );

      const token = response.data.token;
      localStorage.setItem("jwtToken", token);
      setIsAuthenticated(true);

      // Dispatch actions to update Redux state
      dispatch(fetchProfiles());

      navigate("/");
      return null; // No error
    } catch (error) {
      console.error("Error logging in:", error);
      return error.response?.data?.message || "An unexpected error occurred."; // Return error message
    }
  };

  const logout = () => {
    localStorage.removeItem("jwtToken");
    setIsAuthenticated(false);
    dispatch(resetProfileState()); // Clear profile state
    dispatch(resetProfilesState()); // Clear profiles data state
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, loading, login, signup, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
