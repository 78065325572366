import React, { useState, useEffect } from "react";
import styles from "./ProfileSearchDropdown.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useProfile } from "../../../../ProfileContext";
import { useTranslation } from "react-i18next"; // Import useTranslation from react-i18next

const SearchDropdown = ({
  onDropdownVisibilityChange,
  searchResults,
  setSearchResults,
  onAddNewProfile,
}) => {
  const { profiles, selectedProfile, handleProfileSelect } = useProfile();
  const [searchTerm, setSearchTerm] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { t } = useTranslation(); // Destructure t from useTranslation

  useEffect(() => {
    setSearchResults(profiles);
  }, [profiles, selectedProfile]);

  useEffect(() => {
    if (selectedProfile) {
      setSearchTerm(selectedProfile.name);
    } else {
      setSearchTerm("");
    }
  }, [selectedProfile]);

  const handleSearchChange = (e) => {
    const inputValue = e.target.value;
    setSearchTerm(inputValue);

    if (inputValue.trim() === "") {
      setSearchResults(profiles);
      setDropdownVisible(false);
    } else {
      const resultsArray = profiles.filter((profile) =>
        profile.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSearchResults(resultsArray);
      setDropdownVisible(resultsArray.length > 0);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setDropdownVisible(false);
  };

  const handleProfileSelectDropdown = (profile) => {
    handleProfileSelect(profile);
    setDropdownVisible(false);
    setSearchResults(profiles);
    onDropdownVisibilityChange(!dropdownVisible);
  };

  const handleDropdownButtonClick = () => {
    setDropdownVisible(!dropdownVisible);
    onDropdownVisibilityChange(!dropdownVisible);
  };

  const handleAddNewProfile = () => {
    setSearchResults(profiles);
    // Handle the addition of a new profile
    onAddNewProfile(); // Trigger the function to open a profile creation form or page
    setDropdownVisible(false); // Close the dropdown
  };

  return (
    <div className={`${styles.searchDropdown}`}>
      <form onSubmit={handleSearchSubmit}>
        <div
          className={`${styles.searchInputContainer} ${
            dropdownVisible ? styles.searchInputOpen : styles.searchInputClosed
          }`}
        >
          <input
            type="text"
            placeholder={t("profileSearchDropdown.searchPlaceholder")} // Use translation for placeholder
            value={searchTerm || ""} // Ensure searchTerm is always a string
            onChange={handleSearchChange}
            className={`${styles.searchInput}`}
          />
          <button
            type="button"
            className={styles.dropdownButton}
            onClick={handleDropdownButtonClick}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
        </div>
      </form>
      {dropdownVisible && (
        <div className={styles.dropdownMenuWrapper}>
          <ul className={styles.dropdownMenu}>
            <li
              className={styles.dropdownItem}
              onClick={handleAddNewProfile} // Handle "Add new profile" click
            >
              <div className={styles.dropdownItemContent}>
                <span className={styles.dropdownItemLabel}>
                  {t("profileSearchDropdown.addNewProfile")}{" "}
                  {/* Use translation for "Add new profile" */}
                </span>
              </div>
            </li>
            {searchResults.map((profile) => (
              <li
                key={profile.id}
                className={styles.dropdownItem}
                onClick={() => handleProfileSelectDropdown(profile)}
              >
                <div className={styles.dropdownItemContent}>
                  <span className={styles.dropdownItemLabel}>
                    {profile.name}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchDropdown;
