import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./Header.module.css";
import logo from "../../../assets/icons/diet-dash.svg";
import SearchDropdown from "./ProfileSearchDropdown/ProfileSearchDropdown";
import { BsDoorOpen } from "react-icons/bs";
import { useProfile } from "../../../ProfileContext";
import { useAuth } from "../../../AuthContext"; // Import AuthContext
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../LanguageSelector/LanguageSelector"; // Import LanguageSelector

const Header = () => {
  const { t } = useTranslation(); // Hook to get translation function
  const { profiles } = useProfile();
  const { logout } = useAuth(); // Use the logout function from useAuth hook
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchResults, setSearchResults] = useState(profiles);
  const navigate = useNavigate();
  const location = useLocation();

  const getCurrentPage = () => {
    const path = location.pathname.split("/").pop();
    switch (path) {
      case "profile":
        return t(`header.profile`);
      case "diet":
        return t(`header.diet`);
      case "product-list":
        return t(`header.productList`);
      default:
        return "";
    }
  };

  const currentPage = getCurrentPage();

  const handleDropdownVisibility = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  const handleClick = (page) => {
    navigate(`/${page.toLowerCase()}`);
  };

  const handleLogout = () => {
    logout(); // Use the logout function from useAuth hook
  };

  const handleAddNewProfile = () => {
    navigate("/add-profile");
  };

  return (
    <header className={styles.header}>
      <div className={`${styles["side-items"]} ${styles["left-group"]}`}>
        <div className={styles.logo}>
          <img src={logo} alt="Logo" height={48} />
        </div>
      </div>
      <nav className={styles.nav}>
        <div className={styles["header-buttons"]}>
          <button
            className={`${styles.button} ${
              currentPage === t(`header.profile`) ? styles["button-active"] : ""
            }`}
            onClick={() => handleClick("profile")}
          >
            {t(`header.profile`)}
          </button>
          <button
            className={`${styles.button} ${
              currentPage === t(`header.diet`) ? styles["button-active"] : ""
            }`}
            onClick={() => handleClick("diet")}
          >
            {t(`header.diet`)}
          </button>
          <button
            className={`${styles.button} ${
              currentPage === t(`header.productList`)
                ? styles["button-active"]
                : ""
            }`}
            onClick={() => handleClick("product-list")}
          >
            {t(`header.productList`)}
          </button>
        </div>
      </nav>
      <div className={`${styles["side-items"]} ${styles["right-group"]}`}>
        {/* Language Selector */}
        <LanguageSelector /> 
        <img
          src="https://i.imgur.com/OMcdAnC.png"
          className={`${styles["rounded-circle"]}`}
          alt="Profile Avatar"
        />
        <div
          className={`${styles["profile-selection-form"]} ${
            dropdownOpen && searchResults && searchResults.length > 0
              ? styles["open"]
              : styles["closed"]
          }
          `}
        >
          <SearchDropdown
            onDropdownVisibilityChange={handleDropdownVisibility}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            onAddNewProfile={handleAddNewProfile}
          />
        </div>
        <BsDoorOpen
          size={28}
          className={styles["exit-button"]}
          onClick={handleLogout} // Attach the logout function to the button
        />
      </div>
    </header>
  );
};

export default Header;
